import React from 'react';
function FooterButton() {

    return (
        <div className="footerButton">
            <a href="/"  >SATTA KING | &nbsp;</a>
            <a href='/about' >ABOUT | &nbsp;</a>
            <a href='/contact' >CONTACT | &nbsp;</a>
            <a href='/faq' >FAQ | &nbsp;</a>
            <a href='/disclaimer' >DISCLAIMER | &nbsp;</a>
            <a href='/privacypolicy' >PRIVACY POLICY |</a><br /><br />
            © 2023 <a href="index.php">SATTA KING</a>
        </div>
    );
}

export default FooterButton;
